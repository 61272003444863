import { Button, useToastEffect } from "@sequoiacap/client-ui";
import { TrackEvent, track } from "~/utils/analytics";
import { WorkerCommand } from "~/worker-command";
import { asyncCallWithTimeout } from "@sequoiacap/shared/utils/asyncCallWithTimeout";
import { getWorkbox } from "~/utils/getWorkbox";
import { isFromAmpersandWebVC } from "~/network/ampersand-web-vc-bridge";
import { useAPIGetServerProp } from "~/network/user-api";
import { useRouter } from "next/router";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import dynamic from "next/dynamic";
import getConfig from "next/config";
import useAsyncEffect from "@sequoiacap/client-shared/hooks/useAsyncEffect";
import useLightUserInfo from "~/network/useLightUserInfo";
import useMemoCompare from "~/hooks/useMemoCompare";
import useMessageListener from "./useMessageListener";
import useNetworkInfo from "~/hooks/useNetworkInfo";
import useSetupWorkbox from "./useSetupWorkbox";
import useTrackPageFullyLoaded from "./useTrackPageFullyLoaded";

const { publicRuntimeConfig } = getConfig();

const PrecacheComponent = dynamic(
  () => import("~/components/cache/PrecacheComponent"),
  {
    ssr: false,
  },
);

export interface ServiceWorkerContextType {
  loading: boolean;
  swVersion: string | undefined;
  registration?: ServiceWorkerRegistration;
  reloadPrecacheData: () => Promise<void>;
  clearPrecacheData: () => Promise<void>;
  setPageLoading: (loading: boolean) => void;
}

const ServiceWorkerContext = createContext<ServiceWorkerContextType | null>(
  null,
);

export default function useServiceWorker(): ServiceWorkerContextType {
  const context = useContext(ServiceWorkerContext);
  if (!context) {
    throw new Error(
      "useServiceWorker must be used within a ServiceWorkerProvider",
    );
  }
  return context;
}

export const ServiceWorkerProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { userId, isWritableUser } = useLightUserInfo();
  const [registration, setRegistration] = useState<
    ServiceWorkerRegistration | undefined
  >();
  const [showCacheComponent, setShowCacheComponent] = useState(false);
  const { online } = useNetworkInfo();
  const [pageState, setPageState] = useState<{
    loading: boolean;
    path: string | undefined;
  }>({
    loading: true,
    path: undefined,
  });
  const inIframe = !!window.frameElement;
  const router = useRouter();

  const fullyLoaded = useTrackPageFullyLoaded(
    pageState.path,
    pageState.loading,
  );

  const [swVersion, setSWVersion] = useState<{
    version?: string;
    error?: string;
  } | null>(null);

  const [loading, setLoading] = useState(true);

  const { data: serverProp, loading: serverPropLoading } =
    useAPIGetServerProp(userId);

  // Unregister fcm service worker, it should not be used anymore
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      void navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((reg) => {
          if (reg.scope.endsWith("firebase-cloud-messaging-push-scope")) {
            console.log(`[serviceWorker] unregister fcm worker: ${reg.scope}`);
            void reg.unregister();
          }
        });
      });
    }
  }, []);

  const shouldRegister =
    publicRuntimeConfig.CLEAN_SERVICE_WORKER ||
    (userId && serverProp?.serviceWorkerV2 === true);
  useEffect(() => {
    if (serverPropLoading) {
      return;
    }
    console.log("[serviceWorker] checking server prop", shouldRegister);
    if (registration && !shouldRegister) {
      console.log("[serviceWorker] unregister worker by server prop");
      registration
        ?.unregister()
        .then(() => {
          console.log("[serviceWorker] worker unregistered");
        })
        .catch((error) => {
          console.error("[serviceWorker] unregister error", error);
        });
      setRegistration(undefined);
    }
    if (shouldRegister && !registration) {
      console.log("[serviceWorker] register worker by server prop");
      void getWorkbox()?.register();
    }
  }, [serverPropLoading, shouldRegister, registration]);

  useEffect(() => {
    console.log("[serviceWorker] find registration");
    if ("serviceWorker" in navigator) {
      console.log("[serviceWorker] find registration in navigator");
      void navigator.serviceWorker.getRegistrations().then((registrations) => {
        console.log("[serviceWorker] found registrations", registrations);
        if (registrations.length === 0) {
          setLoading(false);
        }
      });
      void navigator.serviceWorker.ready.then((reg) => {
        console.log("[serviceWorker] found registration", reg);
        setRegistration(reg);
      });
    } else {
      setLoading(false);
    }
  }, []);

  // Listen for messages from the parent window
  useMessageListener(
    inIframe ? window.parent.origin : undefined,
    "parent",
    (data: { type: string; path: string }) => {
      console.log("[serviceWorker] Received message from parent:", data);
      if (data.type !== "push") return;

      router.push(data.path).catch((err) => {
        console.error(
          "useTrackPageFullyLoaded/handleParentMessage/route error",
          err,
        );
      });
    },
  );

  const reloadPrecacheData = useCallback(async () => {
    if (
      registration &&
      online &&
      !inIframe &&
      isWritableUser &&
      !isFromAmpersandWebVC()
    ) {
      console.log("[serviceWorker] reload precacheData");
      setShowCacheComponent(true);
    }
  }, [inIframe, online, registration, isWritableUser]);

  const clearPrecacheData = useCallback(async () => {
    console.log("[serviceWorker] clear precacheData");
    const workbox = getWorkbox();
    if (workbox) {
      const { data, error } = await workbox.messageSW({
        command: WorkerCommand.ClearCache,
      });
      if (error) {
        console.error("[serviceWorker] clearPrecacheData/error", error);
        return;
      }
      console.log("[serviceWorker] clearPrecacheData/data", data);
    }
  }, []);

  useAsyncEffect(async () => {
    if (registration && userId && online && fullyLoaded) {
      if (!inIframe) {
        await reloadPrecacheData();
      } else {
        console.log("[serviceWorker] send message to parent");
        window.parent.postMessage(
          {
            source: "iframe",
            type: "fullyLoaded",
            url: window.location.href,
          },
          window.parent.origin,
        );
      }
    }
  }, [registration, userId, online, inIframe, fullyLoaded]);

  useAsyncEffect(async () => {
    if (registration) {
      const workbox = getWorkbox();
      const startTs = Date.now();
      console.log("[serviceWorker] getting version result");

      try {
        const getVersionResult = await asyncCallWithTimeout(
          workbox?.messageSW({
            command: WorkerCommand.GetVersion,
          }) ?? Promise.resolve(null),
          1000,
          `wbGetVersion(${userId})`,
        );

        console.log(
          "[serviceWorker] got version result",
          getVersionResult,
          (Date.now() - startTs) / 1000,
        );
        if (!getVersionResult) {
          setSWVersion({
            error: `sw not enabled`,
          });
          setLoading(false);
          return;
        }
        if (getVersionResult?.error) {
          console.error(
            `[serviceWorker] Error getting sw version - ${getVersionResult.error}`,
          );
          setSWVersion({
            error: `Error getting sw version - ${getVersionResult.error.message}`,
          });
          setLoading(false);
          return;
        }
        setSWVersion({
          version: getVersionResult.data as string,
        });

        if (getVersionResult.data !== publicRuntimeConfig.BUILD_NUMBER) {
          console.log(
            `[serviceWorker] [wb] new version detected ${getVersionResult.data} !== ${publicRuntimeConfig.BUILD_NUMBER}`,
          );
          track(TrackEvent.newSWVersionDetected, {
            newVersion: publicRuntimeConfig.BUILD_NUMBER,
            oldVersion: getVersionResult.data,
          });
        }

        setLoading(false);
      } catch (err) {
        console.error(`[serviceWorker] Error getting sw version - ${err}`);
        setSWVersion({
          error: `Error getting sw version - ${err}`,
        });
        setLoading(false);
      }
    }
  }, [registration]);

  const setPageLoading = useCallback(
    (pageLoading: boolean) => {
      setPageState((state) => {
        if (state.loading === pageLoading && state.path === router.pathname) {
          return state;
        }
        return {
          loading: pageLoading,
          path: router.pathname,
        };
      });
    },
    [router.pathname],
  );

  const newContext = (): ServiceWorkerContextType => {
    return {
      loading,
      swVersion: swVersion?.version,
      registration,
      reloadPrecacheData,
      clearPrecacheData,
      setPageLoading,
    };
  };

  const context = useMemoCompare<ServiceWorkerContextType>(newContext());

  const { showNewVersionToast, onDismissToast } = useSetupWorkbox(
    shouldRegister && !!registration,
  );

  useToastEffect(
    {
      content: showNewVersionToast ? "A new version is available." : undefined,
      actionsSlot: (
        <Button
          key="update"
          variant="plain"
          onClick={() => onDismissToast(true)}
        >
          Update
        </Button>
      ),
      timeout: 0,
    },
    [showNewVersionToast],
  );

  // console.log(
  //   `[serviceWorker] hoc render showCacheComponent=${showCacheComponent} inIframe=${inIframe} online=${online} pageState=${JSON.stringify(
  //     pageState
  //   )} fullyLoaded=${fullyLoaded}`
  // );
  return (
    <ServiceWorkerContext.Provider value={context}>
      {showCacheComponent && <PrecacheComponent />}
      {children}
    </ServiceWorkerContext.Provider>
  );
};

export const useServiceWorkerPageLoadingEffect = (loading: boolean) => {
  const { setPageLoading } = useServiceWorker();

  useEffect(() => {
    setPageLoading(loading);
  }, [loading, setPageLoading]);
};
