import { StorageTimestamp } from "./base/data-type";
import FirestoreDataConverter from "./base/data-converter";

export class ServerPropValue {
  testingString?: string;
  testingArray?: string[];
  serviceWorker?: boolean;
  serviceWorkerV2?: boolean;
  showBeta?: boolean;
  /** Also consider updating PRECACHE_PATHS */
  iosPrecache?: string[];
  cdnImage?: string;
  showEdgeStory?: boolean;
  showEdgeStory2?: boolean;
  hasDMEmailReply?: boolean;
  collapseFeed?: boolean;

  constructor(props: {
    testingString?: string;
    testingArray?: string[];
    serviceWorker?: boolean;
    serviceWorkerV2?: boolean;
    showBeta?: boolean;
    iosPrecache?: string[];
    cdnImage?: string;
    showEdgeStory?: boolean;
    showEdgeStory2?: boolean;
    hasDMEmailReply?: boolean;
    collapseFeed?: boolean;
  }) {
    this.testingString = props.testingString;
    this.testingArray = props.testingArray;
    this.serviceWorker = props.serviceWorker;
    this.serviceWorkerV2 = props.serviceWorkerV2;
    this.showBeta = props.showBeta;
    this.iosPrecache = props.iosPrecache;
    this.cdnImage = props.cdnImage;
    this.showEdgeStory = props.showEdgeStory;
    this.showEdgeStory2 = props.showEdgeStory2;
    this.hasDMEmailReply = props.hasDMEmailReply;
    this.collapseFeed = props.collapseFeed;
  }
}

/**
 * These are values if the user's server prop object doesn't have a value set.
 *
 * Keep in sync with iOS.
 */
export const defaultServerPropValue: ServerPropValue = new ServerPropValue({
  testingString: "",
  testingArray: [],
  serviceWorker: false,
  serviceWorkerV2: true,
  showBeta: false,
  iosPrecache: [],
  cdnImage: "false", // "false" | "true" | "20231016" the release date of the service worker
  showEdgeStory: false,
  showEdgeStory2: false,
  hasDMEmailReply: false,
  collapseFeed: false,
});

/**
 * Values for new users
 */
export const newUserServerPropValue: ServerPropValue = new ServerPropValue({
  testingString: "",
  testingArray: [],
  serviceWorker: false,
  serviceWorkerV2: true,
  showBeta: false,
  iosPrecache: ["/base-camp/2024?precache=true"],
  cdnImage: "20231022",
  showEdgeStory: true,
  showEdgeStory2: true,
  hasDMEmailReply: true,
  collapseFeed: false,
});

export class ServerProp {
  id: string;
  value: ServerPropValue;
  createdAt: StorageTimestamp;
  updatedAt: StorageTimestamp;

  constructor(
    id: string,
    value: ServerPropValue,
    createdAt: StorageTimestamp,
    updatedAt: StorageTimestamp,
  ) {
    this.id = id;
    this.value = value;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}

export const serverPropDataConverter: FirestoreDataConverter<ServerProp> = {
  toFirestoreModel: function (serverProp: ServerProp) {
    return {
      id: serverProp.id,
      value: serverPropValeDataConverter.toFirestoreModel(serverProp.value),
      created_at: serverProp.createdAt,
      updated_at: serverProp.updatedAt,
    };
  },
  fromFirestoreModel: function (data): ServerProp {
    return new ServerProp(
      data.id,
      serverPropValeDataConverter.fromFirestoreModel(data.value),
      data.created_at,
      data.updated_at,
    );
  },
};

const serverPropValeDataConverter: FirestoreDataConverter<ServerPropValue> = {
  toFirestoreModel: function (value: ServerPropValue) {
    return {
      testing_string: value.testingString,
      testing_array: value.testingArray,
      service_worker: value.serviceWorker,
      service_worker_v2: value.serviceWorkerV2,
      show_beta: value.showBeta,
      ios_precache: value.iosPrecache,
      cdn_image: value.cdnImage,
      show_edge_story: value.showEdgeStory,
      show_edge_story2: value.showEdgeStory2,
      has_dm_email_reply: value.hasDMEmailReply,
      collapse_feed: value.collapseFeed,
    };
  },
  fromFirestoreModel: function (data): ServerPropValue {
    return new ServerPropValue({
      testingString: data.testing_string,
      testingArray: data.testing_array,
      serviceWorker: data.service_worker,
      serviceWorkerV2: data.service_worker_v2,
      showBeta: data.show_beta,
      iosPrecache: data.ios_precache,
      cdnImage: data.cdn_image,
      showEdgeStory: data.show_edge_story,
      showEdgeStory2: data.show_edge_story2,
      hasDMEmailReply: data.has_dm_email_reply,
      collapseFeed: data.collapse_feed,
    });
  },
};
