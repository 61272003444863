import { Document } from "../types/Document";
import { FirestoreDataConverter } from "@sequoiacap/shared/models";
import { TrackEvent, track } from "~/utils/analytics";
import { asyncCallWithTimeout } from "@sequoiacap/shared/utils/asyncCallWithTimeout";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { fuego } from "../context";
import { getAlgoliaDocument } from "~/network/algolia/useAlgolia";
import {
  getStoredSWRValueByPath,
  storeSWRValueByPath,
} from "~/network/firebase/network-cache-db";
import {
  hasSubcription,
  registerSubscription,
  unregisterSubscription,
} from "../classes/SubscriptionObject";
import { mutateDocumentInCollection } from "./static-mutations";
import { sleep } from "@sequoiacap/shared/utils";
import { useEffect, useRef } from "react";
import getFirebaseDataConverter from "~/network/firebase/firebase-data-converter";
import useSWR, {
  KeyedMutator,
  SWRConfiguration,
  mutate as staticMutate,
} from "swr";

type Options<T> = {
  /**
   * If `true`, sets up a real-time subscription to the Firestore backend.
   *
   * Default: `false`
   */
  listen?: boolean;

  converter: FirestoreDataConverter<T>;
  /**
   * If `true`, doc returned in `data` will not include the firestore `__snapshot` field.
   *
   * If `false`, it will include a `__snapshot` field. This lets you access the document snapshot, but makes the document not JSON serializable.
   *
   * Default: `true`
   */
  ignoreFirestoreDocumentSnapshotField?: boolean;

  /**
   * If `true`, will return null if there is an error.
   *
   * Default: `false`
   */
  returnNullOnError?: boolean;
} & SWRConfiguration<Document<T> | null>;

type ListenerReturnType<T> = {
  initialData: Document<T>;
  unsubscribe: ReturnType<typeof onSnapshot>;
};

/**
 * Will invalidate the cached value
 * https://swr.vercel.app/docs/mutation
 * @param shouldRevalidate If false and has cached data, resolves with the (now invalidated) cached data; otherwise fetches the new data and resolves to the updated data.
 */
export async function mutateDocument<T>(
  path: string,
  converter: FirestoreDataConverter<T>,
  shouldRevalidate = false,
): Promise<T | undefined> {
  const result = await staticMutate<Document<T>>(
    path,
    async (currentValue: Document<T> | undefined) => {
      if (currentValue && !shouldRevalidate) {
        return currentValue;
      }
      if (!shouldRevalidate) {
        const storedValue = await getStoredSWRValueByPath<Document<T>>(path);
        if (storedValue && storedValue.data !== undefined) {
          return storedValue;
        }
      }
      const r = await getDocument(path, {
        converter: converter,
        ignoreFirestoreDocumentSnapshotField: true,
        firestoreOnly: shouldRevalidate,
      });
      await storeSWRValueByPath(path, r);
      return r;
    },
    false,
  );
  await mutateDocumentInCollection(path, result?.data);
  return result?.data;
}

const IN_FLIGHT_REQUESTS = new Map<string, Promise<Document<unknown>>>();
let IN_FLIGHT_REQUESTS_MAX_COUNT = -1;
let REQUESTS_COUNT = 0;

export function getNumberOfCallsData() {
  return {
    maxInFlightRequests: IN_FLIGHT_REQUESTS_MAX_COUNT,
    requestsCount: REQUESTS_COUNT,
  };
}

export function resetNumberOfCallsData() {
  IN_FLIGHT_REQUESTS_MAX_COUNT = -1;
  REQUESTS_COUNT = 0;
}

async function getDocument<T>(
  path: string,
  {
    converter,
    ignoreFirestoreDocumentSnapshotField = false,
    firestoreOnly = false,
  }: {
    converter: FirestoreDataConverter<T>;
    ignoreFirestoreDocumentSnapshotField: boolean;
    firestoreOnly?: boolean;
  },
): Promise<Document<T>> {
  if (IN_FLIGHT_REQUESTS.has(path)) {
    console.log(`[SWR] getDocument hit in flight dataKey=${path}`);
    return IN_FLIGHT_REQUESTS.get(path) as Promise<Document<T>>;
  }
  const startTs = Date.now();
  console.log(`[SWR] getDocument fetching dataKey=${path} ${startTs}`);
  const firestoreRequest = async () => {
    // When there are multiple tabs opened, for the first few seconds, firestore
    // would return doc not found for the any document that is not yet cached.
    // Add retry 10 times, with a 200ms sleep in between to make sure the document
    // is can be fetched.
    for (let i = 0; i < 10; i++) {
      const document = await getDoc(
        doc(fuego.db, path).withConverter(getFirebaseDataConverter(converter)),
      );
      const result = {
        id: document.id,
        path,
        data: document.data({ serverTimestamps: "estimate" }),
        snapshot: ignoreFirestoreDocumentSnapshotField ? undefined : document,
        s: "fs",
      };
      if (document.exists() || i >= 9) {
        if (i !== 0) {
          track(TrackEvent.getDocumentRetry, {
            path,
            retryCount: i,
            exists: document.exists(),
          });
        }
        return result;
      }
      console.log(`[SWR] getDocument retry dataKey=${path}, sleeping 200ms...`);
      await sleep(200);
    }
    throw new Error(`Could not fetch document ${path}`);
  };

  const algoliaRequest = async () => {
    let fetchedDoc: (Document<T> & { s: string }) | undefined | null =
      undefined;
    try {
      const fetchPromise = (async () => {
        const parts = path.split("/");
        if (parts.length === 2 && parts[0] === "post") {
          return await getAlgoliaDocument<T>("conversation", parts[1], path);
        } else if (
          parts.length === 4 &&
          parts[0] === "post" &&
          parts[2] === "comment"
        ) {
          return await getAlgoliaDocument<T>("conversation", parts[3], path);
        } else if (parts.length === 2 && parts[0] === "user") {
          return await getAlgoliaDocument<T>("user", parts[1], path);
        }
      })();
      fetchedDoc = await asyncCallWithTimeout(
        fetchPromise,
        2000,
        `getDocument:${path}`,
      );
    } catch (e) {
      console.error(
        `[SWR] getDocument error fetching dataKey=${path} from algolia`,
        e,
      );
    }
    if (!fetchedDoc) {
      fetchedDoc = await firestoreRequest();
    }
    return fetchedDoc;
  };

  const request = firestoreOnly ? firestoreRequest() : algoliaRequest();

  IN_FLIGHT_REQUESTS.set(path, request);
  REQUESTS_COUNT++;
  if (IN_FLIGHT_REQUESTS.size > IN_FLIGHT_REQUESTS_MAX_COUNT) {
    IN_FLIGHT_REQUESTS_MAX_COUNT = IN_FLIGHT_REQUESTS.size;
  }
  try {
    const data = await request;
    const duration = Date.now() - startTs;
    console.log(
      `[SWR] getDocument fetched dataKey=${path} in ${duration}ms from ${data.s}`,
    );
    if (duration > 3000 && data.data) {
      track(TrackEvent.getDocumentSlow, {
        path,
        duration,
        s: data.s,
      });
    }
    return data;
  } finally {
    IN_FLIGHT_REQUESTS.delete(path);
  }

  // // update the document in any collections listening to the same document
  // let collection: string | string[] = path.split(`/${data.id}`);

  // collection.pop(); // remove last item, which is the /id
  // collection = collection.join("/"); // rejoin the path
  // if (collection) {
  //   // Don't update collection, we should useDocument on each document if it needs update
  //   // collectionCache.getSWRKeysFromCollectionPath(collection).forEach((key) => {
  //   //   console.log(`[SWR] getDocument updateCollection ${key} dataKey=${path}`);
  //   //
  //   //   mutate(
  //   //     key,
  //   //     (currentState: Document<T>[] = empty.array): Document<T>[] => {
  //   //       // don't mutate the current state if it doesn't include this doc
  //   //       if (!currentState.some((doc) => doc.id === data.id)) {
  //   //         return currentState;
  //   //       }
  //   //       return currentState.map((document) => {
  //   //         if (document.id === data.id) {
  //   //           return {
  //   //             ...document,
  //   //             data: data.data,
  //   //           };
  //   //         }
  //   //         return document;
  //   //       });
  //   //     },
  //   //     false
  //   //   );
  //   // });
  // }
}

const createListenerAsync = async <T>(
  path: string,
  {
    converter,
    ignoreFirestoreDocumentSnapshotField = false,
  }: {
    converter: FirestoreDataConverter<T>;
    ignoreFirestoreDocumentSnapshotField: boolean;
  },
  id: number,
): Promise<ListenerReturnType<T>> => {
  let startTs: number | undefined = Date.now();
  console.log(`[SWR] onDocSnapshot create path=${path}`);
  return await new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(
      doc(fuego.db, path).withConverter(getFirebaseDataConverter(converter)),
      (document) => {
        const docData = document.data();
        const data = {
          id: document.id,
          data: docData,
          path,
          snapshot: ignoreFirestoreDocumentSnapshotField ? undefined : document,
          s: "fs",
        };

        if (startTs) {
          console.log(
            `[SWR] onDocSnapshot updateDoc path=${path} time=${
              Date.now() - startTs
            }ms`,
          );
          startTs = undefined;
        } else {
          console.log(`[SWR] onDocSnapshot updateDoc path=${path}`);
        }

        void staticMutate(path, data, false);
        void staticMutate([path, true], data, false);

        // update the document in any collections listening to the same document
        let collection: string | string[] = path
          .split(`/${document.id}`)
          .filter(Boolean);
        collection.pop(); // remove last item, which is the / . id
        collection = collection.join("/");

        if (collection) {
          // Don't update collection, we should useDocument on each document if it needs update
          // collectionCache
          //   .getSWRKeysFromCollectionPath(collection)
          //   .forEach((key) => {
          //     console.log(
          //       `[SWR] onDocSnapshot updateCollection ${key} for doc ${data.id}`
          //     );
          //
          //     mutate(
          //       key,
          //       (currentState: Document<T>[] = empty.array): Document<T>[] => {
          //         // don't mutate the current state if it doesn't include this doc
          //         if (
          //           !currentState.some(
          //             (currentDoc) => currentDoc.id && currentDoc.id === data.id
          //           )
          //         ) {
          //           return currentState;
          //         }
          //         return currentState.map((document) => {
          //           if (document.id === data.id) {
          //             return {
          //               ...document,
          //               data: data.data,
          //             };
          //           }
          //           return document;
          //         });
          //       },
          //       false
          //     );
          //   });
        }

        // register the subscription, make sure there is one and only one listener for this query
        const subscriptionKey = `[${path}]`;
        registerSubscription(subscriptionKey, id, unsubscribe);

        // the first time the listener fires, we resolve the promise with initial data
        resolve({
          initialData: data,
          unsubscribe: () => {
            // unregister the subscription, to reduce the reference count
            console.log(`[SWR] onDocumentSnapshot unsubscribe [${path}] ${id}`);
            unregisterSubscription(subscriptionKey, id);
          },
        });
      },
      (error) => {
        console.error(`[SWR] onDocSnapshot error [${path}]/error`, error);
        reject(error);
      },
    );
  });
};

// eslint-disable-next-line @typescript-eslint/ban-types
export function useDocument<T extends object>(
  path: string | null,
  options: Options<T>,
): {
  data: Document<T> | null | undefined;
  mutate: KeyedMutator<Document<T> | null>;
  error: Error;
  loading: boolean;
} {
  const {
    listen = false,
    converter,
    ignoreFirestoreDocumentSnapshotField = true,
    returnNullOnError = false,
    refreshInterval,
    ...opts
  } = options;

  // A random number used for subscription id,
  const randomIdRef = useRef(Math.random());

  // if we're listening, the firestore listener handles all revalidation
  const {
    refreshWhenHidden = listen ? false : undefined,
    refreshWhenOffline = listen ? false : undefined,
    revalidateOnFocus = listen ? false : undefined,
    revalidateOnReconnect = listen ? false : undefined,
    dedupingInterval = 1000,
  } = options;

  const swrOptions = {
    ...opts,
    refreshInterval: listen ? 0 : refreshInterval,
    refreshWhenHidden,
    refreshWhenOffline,
    revalidateOnFocus,
    revalidateOnReconnect,
    dedupingInterval,
  };

  // we move listen to a Ref
  // why? because we shouldn't have to include "listen" in the key
  // if we do, then calling mutate() won't be consistent for all
  // documents with the same path.
  const shouldListen = useRef(listen);
  useEffect(() => {
    shouldListen.current = listen;
  }, [listen]);

  const converterRef = useRef(converter);
  useEffect(() => {
    converterRef.current = converter;
  }, [converter]);

  const shouldIgnoreSnapshot = useRef(ignoreFirestoreDocumentSnapshotField);
  useEffect(() => {
    shouldIgnoreSnapshot.current = ignoreFirestoreDocumentSnapshotField;
  }, [ignoreFirestoreDocumentSnapshotField]);

  const listenCacheDataRef = useRef<{
    path: string;
    data: Document<T> | null | undefined;
    unsubscribe?: () => void;
  }>();

  const swr = useSWR<Document<T> | null>(
    listen && path ? [path, listen] : path,
    async (key: string | [string, boolean]) => {
      let fetcherPath;
      if (key instanceof Array) {
        fetcherPath = key[0];
      } else {
        fetcherPath = key;
      }
      if (shouldListen.current) {
        if (listenCacheDataRef.current?.path !== fetcherPath) {
          listenCacheDataRef.current?.unsubscribe?.();
          listenCacheDataRef.current = {
            path: fetcherPath,
            data: null,
          };
          const { unsubscribe, initialData } = await createListenerAsync<T>(
            fetcherPath,
            {
              converter: converterRef.current,
              ignoreFirestoreDocumentSnapshotField:
                shouldIgnoreSnapshot.current,
            },
            randomIdRef.current,
          );
          listenCacheDataRef.current.unsubscribe = unsubscribe;
          listenCacheDataRef.current.data = initialData;
          return initialData;
        } else {
          return listenCacheDataRef.current.data ?? null;
        }
      } else {
        listenCacheDataRef.current = undefined;
      }
      try {
        const fetchedData = await getDocument<T>(fetcherPath, {
          converter: converterRef.current,
          ignoreFirestoreDocumentSnapshotField: shouldIgnoreSnapshot.current,
        });
        return fetchedData;
      } catch (fetchError) {
        if (returnNullOnError) {
          return null;
        }
        console.error(`[swr] useDocument path=${path} error`, fetchError);
        throw fetchError;
      }
    },
    swrOptions,
  );

  const { data, error, mutate: connectedMutate } = swr;

  // if listen changes,
  // we run revalidate.
  // This triggers SWR to fetch again
  // Why? because we don't want to put listen or memoQueryString
  // in the useSWR key. If we did, then we couldn't mutate
  // based on path. If we had useSWR(['users', { where: ['name', '==, 'fernando']}]),
  // and we updated the proper `user` dictionary, it wouldn't mutate, because of
  // the key.
  // thus, we move the `listen` and `queryString` options to refs passed to `useSWR`,
  // and we call `revalidate` if either of them change.
  const mounted = useRef(false);
  useEffect(() => {
    if (mounted.current) void revalidateRef.current();
    else mounted.current = true;
  }, [listen]);

  // this MUST be after the previous effect to avoid duplicate initial validations.
  // only happens on updates, not initial mount.
  const revalidateRef = useRef(connectedMutate);
  useEffect(() => {
    revalidateRef.current = connectedMutate;
  });

  useEffect(() => {
    return () => {
      // clean up listener on unmount if it exists
      if (listenCacheDataRef.current?.unsubscribe) {
        listenCacheDataRef.current.unsubscribe();
        listenCacheDataRef.current = undefined;
      }
    };
    // should depend on the path, and listen being the same...
  }, [path, listen]);

  // When there is data and we are listening to the collection,
  // make sure there is a listener on the collection.
  // If there is no listener, call mutate would create a new listener
  // When there is a listener, we register to the subscription
  useEffect(() => {
    if (data && listen && path) {
      const subscriptionKey = `[${path}]`;
      if (!hasSubcription(subscriptionKey)) {
        void connectedMutate();
      } else {
        registerSubscription(subscriptionKey, randomIdRef.current);
      }
    }
  }, [data, listen, connectedMutate, path]);

  // Unregister from the subscription when unmountingj:w
  useEffect(() => {
    if (listen && path) {
      const subscriptionKey = `[${path}]`;
      const id = randomIdRef.current;
      return () => {
        unregisterSubscription(subscriptionKey, id);
      };
    }
  }, [listen, path]);

  if (listenCacheDataRef.current?.path === path) {
    listenCacheDataRef.current.data = data;
  }

  // /**
  //  * `set(data, SetOptions?)`: Extends the `firestore` document `set` function.
  //  * - You can call this when you want to edit your document.
  //  * - It also updates the local cache using SWR's `mutate`. This will prove highly convenient over the regular Firestore `set` function.
  //  * - The second argument is the same as the second argument for [Firestore `set`](https://firebase.google.com/docs/firestore/manage-data/add-data#set_a_document).
  //  */
  // const set = useCallback(
  //   (
  //     setData: Partial<AllowType<T, FieldValue>>,
  //     setOptions: SetOptions = {}
  //   ) => {
  //     if (!listen) {
  //       // we only update the local cache if we don't have a listener set up
  //       // Why? firestore handles this for us for listeners.
  //
  //       connectedMutate((prevState): Document<T> => {
  //         // default we set merge to be false. this is annoying, but follows Firestore's preference.
  //         if (!setOptions?.merge)
  //           return {
  //             data: setData as unknown as T,
  //             id: prevState?.id,
  //             snapshot: undefined,
  //           };
  //         return {
  //           ...(prevState ?? {}),
  //           data: setData,
  //         } as unknown as Document<T>;
  //       });
  //     }
  //     if (!path) return null;
  //     return fuego.db.doc(path).set(setData, setOptions);
  //   },
  //   [path, listen, connectedMutate]
  // );

  // /**
  //  * - `update(data)`: Extends the Firestore document [`update` function](https://firebase.google.com/docs/firestore/manage-data/add-data#update-data).
  //  * - It also updates the local cache using SWR's `mutate`. This will prove highly convenient over the regular `set` function.
  //  */
  // const update = useCallback(
  //   async (updateData: Partial<AllowType<Data, FieldValue>>) => {
  //     if (!listen) {
  //       // we only update the local cache if we don't have a listener set up
  //
  //       connectedMutate((prevState): Doc => {
  //         return {
  //           ...(prevState ?? {}),
  //           ...updateData,
  //         } as unknown as Doc;
  //       });
  //     }
  //     if (!path) return null;
  //     return fuego.db.doc(path).update(updateData);
  //   },
  //   [listen, path, connectedMutate]
  // );

  // const connectedDelete = useCallback(() => {
  //   return deleteDocument(path, listen);
  // }, [path, listen]);

  const isValidData = data || (returnNullOnError && data === null);

  return {
    data,
    mutate: connectedMutate,
    error,
    // set,
    // update,
    loading: !!path && !error && !isValidData,
    // deleteDocument: connectedDelete,
    /**
     * A function that, when called, unsubscribes the Firestore listener.
     *
     * The function can be null, so make sure to check that it exists before calling it.
     *
     * **Note**: This is not necessary to use. `useDocument` already unmounts the listener for you. This is only intended if you want to unsubscribe on your own.
     */
  };
}

// const useSubscription = (path: string) => {
//   const unsubscribeRef = useRef<
//     ReturnType<typeof createListener>['unsubscribe'] | null
//   >(null)

//   const swr = useSWR([path], path => {
//     const { unsubscribe, latestData } = createListener(path)
//     unsubscribeRef.current = unsubscribe
//     return latestData()
//   })

//   useEffect(() => {
//     return () => {
//       if (unsubscribeRef.current) {
//         unsubscribeRef.current()
//       }
//     }
//   }, [path])
//   return swr
// }
